/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
#network {
  background-color: #000000;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: -1;
}

body {
  color: gainsboro;
}

.backRows {
  background-color: rgba(52, 58, 64, 0.6);
  border-radius: 0.3rem;
}

.stats {
  margin-top: 0.5rem;
  padding-top: 0.3rem;
  text-align: center;
}

.stats ul {
  padding-inline-start: 0rem;
  list-style-type: none;
}

.content {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.table {
  color: #ffffff;
  font-size: 0.9rem;
}

.clickable {
  cursor: pointer;
}

.nav-link {
  display: block;
  padding: 0rem 1rem;
}

@media (min-width: 768px) {
  .navbar-main .container .navbar-collapse {
    position: absolute;
    right: 0.2rem;
    bottom: 0.4rem;
  }
}

.wrapperClassName {
  border-style: solid;
  border-radius: 0.8rem;
  padding: 0.4rem;
}

.deleteModal {
  color: white;
}
.deleteModal .modal-content {
  background: black;
  border: solid;
}

.table-dark {
  color: black;
  background-color: rgba(52, 58, 64, 0.6);
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: rgba(255, 238, 186, 0.6);
  color: black;
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: rgba(184, 218, 255, 0.6);
  color: black;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: rgba(245, 198, 203, 0.6);
  color: black;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: rgba(195, 230, 203, 0.6);
  color: black;
}

.table .btn {
  padding: 1px 6px;
  font-size: 0.9rem;
  display: inherit;
}

.wrapperClassName svg {
  vertical-align: unset;
}

.descriptionContent > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.descriptionContent div {
  text-align: center;
}

.monospace {
  font-family: monospace;
}

.dropdown-menu {
  background-color: rgba(20, 20, 20, 0.9);
}

.dropdown-menu > .dropdown-item {
  color: white;
}

.dropdown-menu > .dropdown-item:focus,
.dropdown-menu > .dropdown-item:hover {
  color: black;
}

.img-wrap img {
  max-width: 100%;
  height: auto;
}

.visible-btn {
  opacity: 1 !important;
  padding: 0.3em;
}

.solved-btn {
  opacity: 0.3 !important;
  padding: 0.3em;
}

.img-btn {
  padding: 0.3em;
  opacity: 0.9 !important;
}

.img-btn:disabled {
  padding: 0.3em;
  opacity: 0.5 !important;
}

html,
body {
  width: 100%;
  height: 100%;
}

.flexbox-parent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
}

.flexbox-item-grow {
  flex: 1; /* same as flex: 1 1 auto; */
}

.fill-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
}
.fill-area-content {
  /* Needed for when the area gets squished too far and there is content that can't be displayed */
  /* overflow: auto;*/
}
.flexbox-relative {
  position: relative;
}
.flexbox-absolute {
  position: absolute;
}

.modal-content {
  background-color: #000000;
  border: 1px solid #fff;
}

.pexesoUserColor {
  width: 20px;
  height: 20px;
}

.wrongFade {
  opacity: 0.5;
  transition: all 250ms linear 0s;
}

.successFade {
  opacity: 0.5;
  transition: all 250ms linear 0s;
}
